import {Tr} from "@/i18n/i18n";
import Scene = Phaser.Scene;
import {Keys} from "@/constants/key";
import TextUtils from "@/utils/text-utils";
import {Fonts} from "@/constants/font";
import {CampaignType, ChestType, Lang, Player} from "@/store/player/types";
import {SceneName} from "@/model/scene-name";
import {SceneService} from "@/services/scene-service";
import BattleStore from "@/store/battle";
import PlayerStore from "@/store/player";
import {IntegrationUtils} from "@/utils/integration-utils";
import {MusicUtils} from "@/utils/music-utils";
import {Settings} from "@/constants/settings";
import {GameState} from "@/constants/game-state";
import Text = Phaser.GameObjects.Text;
import ParticleEmitter = Phaser.GameObjects.Particles.ParticleEmitter;
import Graphics = Phaser.GameObjects.Graphics;
import SpriteUtils from "@/utils/sprite-utils";
import AnimationUtils from "@/utils/animation-utils";
import ArtifactUtils from "@/utils/artifact-utils";
import {Urls} from "@/constants/urls";
import CU from "./c-u";
import Sprite = Phaser.GameObjects.Sprite;
import { LoadingAnimStyle } from "@/scenes.ui/types";

export enum PriorityType { NEW_PLAYER, ROAD, BATTLE }

export class LoadUtils {

    public static prioritizedLoad(scene: Scene, priorityType: PriorityType) {
        // let player = PlayerStore.getPlayer();
        //
        // //
        // //
        // // ZERO GROUP:
        // //
        // //
        //
        // if (priorityType === PriorityType.NEW_PLAYER) {
        //     LoadUtils.loadImage(scene, Keys.IMG_PARTICLE_CIRCLE);
        // }
        //
        // //
        // //
        // // FIRST GROUP:
        // //
        // //
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_EDU);
        // LoadUtils.loadImage(scene, Keys.PLACE_SHADOW);
        // LoadUtils.loadImage(scene, Keys.IMG_CLICK_FLASH);
        // LoadUtils.loadImage(scene, Keys.IMG_CROSSHAIR);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_PLACES_1);
        // LoadUtils.loadBattleMusic(scene);
        //
        // LoadUtils.loadImage(scene, Keys.UI_WINDOW_HORIZONTAL);
        // LoadUtils.loadImage(scene, Keys.UI_WINDOW_MIDDLE);
        // LoadUtils.loadImage(scene, Keys.UI_WINDOW_MIDDLE_BIG);
        // LoadUtils.loadImage(scene, Keys.IMG_VICTORY_KNIGHT);
        // LoadUtils.loadImage(scene, Keys.IMG_CARD_SHADE);
        //
        // LoadUtils.loadImage(scene, Keys.BRIDGE);
        //
        // LoadUtils.loadCreatureSceneMusic(scene);
        //
        //
        // //
        // //
        // // SECOND GROUP:
        // //
        // //
        //
        // LoadUtils.loadImage(scene, Keys.PROFILE_BACK);
        // LoadUtils.loadImage(scene, Keys.SHOP_BACK);
        // LoadUtils.loadImage(scene, Keys.TASK_BACK);
        // LoadUtils.loadImage(scene, Keys.TASK_BOTTOM);
        // LoadUtils.loadImage(scene, Keys.SHOP_BOTTOM);
        // LoadUtils.loadImage(scene, Keys.IMG_CROSS_SNOW);
        // LoadUtils.loadImage(scene, Keys.IMG_SKULL_ICON);
        // LoadUtils.loadImage(scene, Keys.IMG_GREY_BACKGROUND);
        // LoadUtils.loadImage(scene, Keys.IMG_PLAYER_TURN);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_EXPERIENCE);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_CUPS);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_GEM);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_CRYSTAL_RED);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_GOLD);
        // LoadUtils.loadImage(scene, Keys.IMG_REWARD_CRYSTAL_YELLOW);
        //
        // let stair = !!player ? player.stair : 1;
        //
        // LoadUtils.loadImage(scene, Keys.IC_100_MITHRIL);
        // LoadUtils.loadImage(scene, Keys.IC_BR_CUP);
        //
        // LoadUtils.loadImage(scene, Keys.UI_BOTTOM_MENU);
        //
        LoadUtils.loadImage(scene, Keys.GREEN_HORIZONTAL_BUTTON); //required to display correct button text
        LoadUtils.loadImage(scene, Keys.UI_SHOP_X);
        //
        // LoadUtils.loadImage(scene, Keys.UI_NOTIF_PANEL);
        // LoadUtils.loadImage(scene, Keys.UI_ART_BASIC_WINDOW);
        // LoadUtils.loadImage(scene, Keys.UI_WARNING);
        // LoadUtils.loadImage(scene, Keys.CAMPAIGN_RIBBON);
        // LoadUtils.loadImage(scene, Keys.CAMPAIGN_RIBBON_BLUE);
        // LoadUtils.loadImage(scene, Keys.OFFER_RIBBON);
        // LoadUtils.loadImage(scene, Keys.OFFER_LIGHT_BACK);
        // LoadUtils.loadImage(scene, Keys.OFFER_SHOP_ICON);
        // LoadUtils.loadImage(scene, Keys.OFFER_SHOP_ICON);
        // LoadUtils.loadImage(scene, Keys.OFFER_RIBBON_FAT);
        //
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_EDU_2);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_CREATURES_ROAD);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_CREATURES_BATTLE_1);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_BOOST);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_SUPERSHOP);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_IC_100);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_BADGES);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_FORCES_BIG);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_FORCES_LARGE);
        //
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_FORCES_2);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_FORCES_BIG_2);
        //
        // LoadUtils.loadStairDepending(scene, stair, player);
        //
        // for (let g of Keys.ADDITIONAL_GAINABLES) {
        //     scene.load.image(g, `assets/gain/${g}.png`);
        // }
        //
        // for (let creature of Keys.ADDITIONAL_CREATURES) {
        //     scene.load.image(Keys.battleCreature(creature), `assets/creatures/${creature}.png`);
        // }
        //
        // for (let place of Keys.ADDITIONAL_PLACES) {
        //     scene.load.image(place, `assets/place/${place}.png`);
        // }
        //
        // ArtifactUtils.loadArtifacts(scene);
        //
        // for (let r of Keys.REPLICS) {
        //     scene.load.image(r, `assets/replics/${r}.png`);
        // }
        //
        // LoadUtils.loadImage(scene, Keys.FRM_RADIO_OUT);
        // LoadUtils.loadImage(scene, Keys.FRM_RADIO_IN);
        // LoadUtils.loadImage(scene, Keys.FRM_UP);
        // LoadUtils.loadImage(scene, Keys.FRM_UP_GREEN);
        //
        // for (let anim of Keys.ANIMS) {
        //     if (anim === Keys.LOADING_ANIM) {
        //         continue;
        //     }
        //     let size = AnimationUtils.getFrameSize(anim);
        //     scene.load.spritesheet(anim, `assets/anim/${anim}.png`, { frameWidth: size.x, frameHeight: size.y });
        // }
        //
        // //
        // //
        // // PRE LAST GROUP:
        // //
        // //
        //
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_POINTERS);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_POINTERS_ADD);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_CONSUMABLES_SHADE);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_CONSUMABLES_NO_SHADE);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GAINABLE);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GAINABLE_2);
		// LoadUtils.loadAtlas(scene, Keys.ATLAS_SHOP_UI);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_BANK);
        // LoadUtils.loadImage(scene, Keys.UI_SHOP_ARTIFACT_EFFECT_DEFENCE_ICON);
        // LoadUtils.loadImage(scene, Keys.UI_SHOP_ARTIFACT_EFFECT_ATTACK_ICON);
        // LoadUtils.loadImage(scene, Keys.UI_SHOP_PAGE_LOCK);
        // LoadUtils.loadImage(scene, Keys.ARENA_HEAD);
        // LoadUtils.loadImage(scene, Keys.ARENA_CUP);
        //
        // //
        // //
        // // THE LAST GROUP:
        // //
        // //
        //
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_RUNES);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_RUNES_4);
        // LoadUtils.loadImage(scene, Keys.CLAN_RATING);
        // LoadUtils.loadImage(scene, Keys.CLAN_BUILDING);
        // LoadUtils.loadImage(scene, Keys.CLAN_ONLINE);
        // LoadUtils.loadImage(scene, Keys.CLAN_OFFLINE);
        // LoadUtils.loadImage(scene, Keys.CLAN_GEAR);
        // LoadUtils.loadImage(scene, Keys.CL_SNOWFLAKE);
        // LoadUtils.loadImage(scene, Keys.CL_INVITE);
        // LoadUtils.loadImage(scene, Keys.CL_KICK);
        // LoadUtils.loadImage(scene, Keys.CL_UP);
        // LoadUtils.loadImage(scene, Keys.CL_DOWN);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GIFTS);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GIFTS_2);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GIFTS_3);
        // LoadUtils.loadAtlas(scene, Keys.ATLAS_GIFTS_4);
        //
        // scene.load.start();
    }

    public static loadCampaignDepending(scene: Scene, player: Player | undefined): void {
        let campaignTypes: CampaignType[] = PlayerStore.getCampaignTypes();
        let chestsGraphicsLoaded = false;
        if (player && (player.chestType === ChestType.SILVER || player.chestType === ChestType.GOLD
                || player.chestType === ChestType.AZURE || player.chestType === ChestType.LAVA
                || player.chestType === ChestType.AMBER || player.chestType === ChestType.JADE)) {
            LoadUtils.loadCampaignChests(scene);
            chestsGraphicsLoaded = true;
        }
        if (campaignTypes && campaignTypes.length > 0) {
            let campType = campaignTypes[0];
            if (campType == CampaignType.CHEST && !chestsGraphicsLoaded) {
                LoadUtils.loadCampaignChests(scene);
            } else if (campType == CampaignType.WHEEL) {
                LoadUtils.loadCampaignWheel(scene);
            }
        }
    }

    public static goToNewSceneAfterLoad(scene: Scene, sceneService: SceneService) {
        PlayerStore.SET_GAME_START();
        let player: Player = PlayerStore.getPlayer();

        if (!player) {
            sceneService.startSceneInstant(scene, SceneName.CHAR_SELECTION_SCENE);

        } else if (player.battle) {

            GameState.loadingType = PriorityType.BATTLE;
            BattleStore.getBattle().then(() => {
                sceneService.startSceneInstant(scene, SceneName.BATTLE_SCENE);
            });

        } else {

            GameState.loadingType = PriorityType.ROAD;
            sceneService.startSceneInstant(scene, SceneName.ROAD_SCENE, {firstStart: true});

        }
    }

    public static showSimpleLoadingScreen(scene: Scene, canvas: HTMLCanvasElement, loaderState: LoaderState) {
        this.doShowSimpleLoadingScreen(scene, canvas, canvas.height / 2 / CU.S + 25, loaderState);
    }

    public static showLoadingScreen(scene: Scene, canvas: HTMLCanvasElement, loaderState: LoaderState) {

        SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S,canvas.height / 2 / CU.S,  Keys.IMG_LOAD_SCREEN_BACKGROUND);
        if (Settings.LANG_TYPE === Lang.EN) {
            SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S + 112,canvas.height / 2 / CU.S - 262, Keys.ATLAS_PRELOAD, Keys.IMG_LOAD_SCREEN_TITLE_EN);
        } else {
            SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S + 112,canvas.height / 2 / CU.S - 262, Keys.ATLAS_PRELOAD, Keys.IMG_LOAD_SCREEN_TITLE_RU);
        }

        if (IntegrationUtils.isMystoreUser()) {
            SpriteUtils.addSprite(scene, canvas.width - 60,canvas.height - 25,  Keys.AGE_12);
        }

        this.doShowSimpleLoadingScreen(scene, canvas, canvas.height / CU.S, loaderState);
    }

    private static doShowSimpleLoadingScreen(scene: Scene, canvas: HTMLCanvasElement, yPos: number, loaderState: LoaderState) {
        let loadBarBack = scene.add.graphics();
        loadBarBack.fillStyle(0x000000, 1);
        loadBarBack.fillRoundedRect(canvas.width / 2 - 133 * CU.S, yPos * CU.S - 60 * CU.S, 267 * CU.S, 20 * CU.S, 10 * CU.S);

        loaderState.loadBarOuter = SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S - 132, yPos - 50, Keys.ATLAS_PRELOAD, Keys.IMG_LOAD_SCREEN_BAR_OUTER)
            .setOrigin(0, 0.5);

        SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S, yPos - 50, Keys.ATLAS_PRELOAD, Keys.IMG_LOAD_SCREEN_BAR_INNER);

        loaderState.loadingText = TextUtils.addTextByFamily(scene, Tr('zagruzka'), canvas.width / 2 / CU.S, yPos - 25,
            '#ffffff', 13, 'normal', CU.CANVAS_WIDTH, Fonts.MYRIADPRO_REGULAR);


        scene.load.on('progress', (value: number) => {
            let newPercent = value * 100;
            // let newPercent = Math.floor(value * 100 * (1.6 - value * 0.6));
            GameState.LOADED_PCT = newPercent;
            if (newPercent >= 5 && !Settings.LOGGED_LOAD_FIVE) {
                PlayerStore.logLoadFive();
                Settings.LOGGED_LOAD_FIVE = true;
            }
            if (newPercent >= 50 && !Settings.LOGGED_LOAD_HALF) {
                PlayerStore.logLoadHalf();
                Settings.LOGGED_LOAD_HALF = true;
            }
            loaderState.targetPercent = Math.floor(10 + newPercent * 0.9);
        });
    }

    private static haveDisabledAtlases = window.location.href.indexOf("disable_") > -1;

    public static loadAtlas(scene: Scene, key: string) {
        if (!scene.textures.exists(key)) {
            if (!this.haveDisabledAtlases || window.location.href.indexOf("disable_" + key) == -1 ){
                let path = Urls.ATLASES.get(key);
                scene.load.atlas(key, path + (Urls.ATLASES_WEBP.has(key) ? '.webp' : '.png'), path + '.json');
            }
        }
    }

    public static loadImage(scene: Scene, key: string) {
        if (!scene.textures.exists(key)) {
            let path = Urls.IMAGES.get(key);
            scene.load.image(key, path);
        }
    }

    public static loadMusic(scene: Scene, player: Player) {
        let muted = player ? player.soundMute : false;

        if (!muted) {
            let musicKeys: string[] = MusicUtils.getRoadMusicKeysToLoad(player);

            scene.load.audio(Keys.SOUND_GAIN_LOOT, ['assets/sound/ogg/gain-loot.ogg', 'assets/sound/mp3/gain-loot.mp3']);

            for (let music of musicKeys) {
                scene.load.audio(music, [`assets/sound/ogg/${music}.ogg`, `assets/sound/mp3/${music}.mp3`]);
            }
            MusicUtils.ROAD_MUSIC_KEYS = musicKeys;

            if (!player || player.level < 2) {
                scene.load.audio(Keys.SOUND_HELP_MAN_RU, ['assets/sound/ogg/help_man_ru.ogg', 'assets/sound/mp3/help_man_ru.mp3']);
                scene.load.audio(Keys.SOUND_HELP_WOM_RU, ['assets/sound/ogg/help_wom_ru.ogg', 'assets/sound/mp3/help_wom_ru.mp3']);
                scene.load.audio(Keys.SOUND_HELP_MAN_EN, ['assets/sound/ogg/help_man_en.ogg', 'assets/sound/mp3/help_man_en.mp3']);
                scene.load.audio(Keys.SOUND_HELP_WOM_EN, ['assets/sound/ogg/help_wom_en.ogg', 'assets/sound/mp3/help_wom_en.mp3']);
            }

            scene.load.audio(Keys.SOUND_HORSE_WALK, ['assets/sound/ogg/horse-walk.ogg', 'assets/sound/mp3/horse-walk.mp3']);

            scene.load.audio(Keys.SOUND_PLACE_OPEN, ['assets/sound/ogg/place-open.ogg', 'assets/sound/mp3/place-open.mp3']);
            scene.load.audio(Keys.SOUND_BUTTON_CLICK, ['assets/sound/ogg/button-click.ogg', 'assets/sound/mp3/button-click.mp3']);
            scene.load.audio(Keys.SOUND_CREATURE_CLICK, ['assets/sound/ogg/creature-click.ogg', 'assets/sound/mp3/creature-click.mp3']);
            scene.load.audio(Keys.SOUND_BUILDING, ['assets/sound/ogg/structure-building.ogg', 'assets/sound/mp3/structure-building.mp3']);
            scene.load.audio(Keys.SOUND_SELECT, ['assets/sound/ogg/select-1.ogg', 'assets/sound/mp3/select-1.mp3']);
            scene.load.audio(Keys.SOUND_COMPLETED, ['assets/sound/ogg/completed.ogg', 'assets/sound/mp3/completed.mp3']);
            scene.load.audio(Keys.SOUND_LEVEL_UP, ['assets/sound/ogg/level-up.ogg', 'assets/sound/mp3/level-up.mp3']);

            Settings.MUSIC_LOADED = true;
        }
    }

    public static isTheMedievalMusicInCreatureScene() {
        return PlayerStore.getPlayer().stair > 500;
    }

    public static loadCreatureSceneMusic(scene: Scene) {
        if (LoadUtils.isTheMedievalMusicInCreatureScene()) {
            scene.load.audio(Keys.MUSIC_THE_MEDIEVAL, ['assets/sound/ogg/the_medieval.ogg', 'assets/sound/mp3/the_medieval.mp3']);
        } else {
            scene.load.audio(Keys.MUSIC_FANTASY_SNEAK, ['assets/sound/ogg/fantasy-sneak.ogg', 'assets/sound/mp3/fantasy-sneak.mp3']);
        }
    }

    public static loadBattleMusic(scene: Scene) {
        scene.load.audio(Keys.SOUND_DEFEAT, ['assets/sound/ogg/defeat.ogg', 'assets/sound/mp3/defeat.mp3']);
        scene.load.audio(Keys.SOUND_VICTORY, ['assets/sound/ogg/victory.ogg', 'assets/sound/mp3/victory.mp3']);
        scene.load.audio(Keys.SOUND_VICTORY_2, ['assets/sound/ogg/level_won.ogg', 'assets/sound/mp3/level_won.mp3']);
        scene.load.audio(Keys.SOUND_WIND_UP, ['assets/sound/ogg/wind-up.ogg', 'assets/sound/mp3/wind-up.mp3']);
        scene.load.audio(Keys.SOUND_WIND_DOWN, ['assets/sound/ogg/wind-down.ogg', 'assets/sound/mp3/wind-down.mp3']);
        scene.load.audio(Keys.SOUND_ENEMY_PUNCH_1, ['assets/sound/ogg/enemy-punch-1.ogg', 'assets/sound/mp3/enemy-punch-1.mp3']);
        scene.load.audio(Keys.SOUND_ENEMY_PUNCH_2, ['assets/sound/ogg/enemy-punch-2.ogg', 'assets/sound/mp3/enemy-punch-2.mp3']);
        scene.load.audio(Keys.SOUND_ENEMY_PUNCH_3, ['assets/sound/ogg/enemy-punch-3.ogg', 'assets/sound/mp3/enemy-punch-3.mp3']);
        scene.load.audio(Keys.SOUND_PLAYER_PUNCH_1, ['assets/sound/ogg/player-punch-1.ogg', 'assets/sound/mp3/player-punch-1.mp3']);
        scene.load.audio(Keys.SOUND_PLAYER_PUNCH_2, ['assets/sound/ogg/player-punch-2.ogg', 'assets/sound/mp3/player-punch-2.mp3']);
        scene.load.audio(Keys.SOUND_PLAYER_PUNCH_3, ['assets/sound/ogg/player-punch-3.ogg', 'assets/sound/mp3/player-punch-3.mp3']);
        scene.load.audio(Keys.SOUND_AWARD, ['assets/sound/ogg/award.ogg', 'assets/sound/mp3/award.mp3']);
        scene.load.audio(Keys.SOUND_USE_HEAL_POTION, ['assets/sound/ogg/use_heal_potion.ogg', 'assets/sound/mp3/use_heal_potion.mp3']);
        scene.load.audio(Keys.SOUND_USE_SCROLL, ['assets/sound/ogg/use_scroll.ogg', 'assets/sound/mp3/use_scroll.mp3']);
        scene.load.audio(Keys.SOUND_RESURRECT, ['assets/sound/ogg/resurrect.ogg', 'assets/sound/mp3/resurrect.mp3']);
    }

    private static loadCampaignChests(scene: Scene) {
        scene.load.image(Keys.CHEST_SILVER_CLOSED, 'assets/chest/silverChest.png');
        scene.load.image(Keys.CHEST_GOLDEN_CLOSED, 'assets/chest/goldenChest.png');
        scene.load.image(Keys.CHEST_SILVER_OPENED, 'assets/chest/silverChestOpened.png');
        scene.load.image(Keys.CHEST_GOLDEN_OPENED, 'assets/chest/goldenChestOpened.png');
        scene.load.image(Keys.CHEST_GOLDEN_IC, 'assets/chest/goldenChestIcon.png');
        scene.load.image(Keys.CHEST_SILVER_IC, 'assets/chest/silverChestIcon.png');
    }

    private static loadCampaignWheel(scene: Scene) {
        scene.load.image(Keys.WHEEL, 'assets/wheel/wheel.png');
        scene.load.image(Keys.WHEEL_BACK_BTN, 'assets/wheel/back_btn.png');
        scene.load.image(Keys.WHEEL_CURSOR, 'assets/wheel/cursor.png');
        scene.load.image(Keys.WHEEL_CURSOR_2, 'assets/wheel/cursor2.png');
        // scene.load.image(Keys.WHEEL_DECOR, 'assets/wheel/decor.png');
        scene.load.image(Keys.WHEEL_ROLL_BTN, 'assets/wheel/roll_btn.png');
        scene.load.image(Keys.WHEEL_SPLASH, 'assets/wheel/splash.png');
        scene.load.image(Keys.WHEEL_SPLASH_2, 'assets/wheel/splash2.png');
        scene.load.image(Keys.WHEEL_WING, 'assets/wheel/wing.png');
        // scene.load.image(Keys.WHEEL_PROGRESS, 'assets/wheel/x2progress.png');
        scene.load.image(Keys.TO_WHEEL_BTN, 'assets/wheel/button_violet.png');
        scene.load.image(Keys.WHEEL_SMALL, 'assets/wheel/wheel_small.png');
        scene.load.image(Keys.WHEEL_BG_BOTTOM, 'assets/wheel/bg_bottom.png');

        scene.load.image(Keys.HEALTH_TO_ALL, 'assets/wheel/health_to_all.png');
        scene.load.image(Keys.HEALTH_TO_ONE, 'assets/wheel/health_to_one.png');
        scene.load.image(Keys.ATTACK_TO_ALL, 'assets/wheel/attack_to_all.png');
        scene.load.image(Keys.DEFENCE_TO_ALL, 'assets/wheel/defence_to_all.png');
    }

    public static drawClouds(scene:Scene, canvas:HTMLCanvasElement){        
        let bg = SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S,canvas.height / 2 / CU.S,  Keys.CLOUD2).setScale(5, 15).setAlpha(0.5);

        let scale = 1.1;

        let x0 = SceneService.ENTER_NEW_SCENE_DELAY /100;
        let clouds:Sprite[] = []
        for (let i=0; i<4;i++){
            for (let j=0; j<9;j++){
                let cloud = SpriteUtils.addSprite(scene, x0+250*i*scale + j*60*scale - 250,0+140*j*scale,  Keys.CLOUD2).setScale(scale * CU.S).setAlpha(1);
                clouds.push(cloud);
                if((i+j)%2 == 0){
                    cloud.scaleX *=-1;
                }
                if((i+j)%4 == 0){
                    cloud.scaleY *=-1;
                }
            }
        }

        scene.tweens.add({
            targets: clouds,
            x: '+=100',
            duration: 10000,
            delay:0,
            onStop() {
                clouds.forEach(s=> s.destroy())
            },
        });
    }

    public static drawLoadingScreen(scene: Scene, completeCallback?: () => unknown): void {
        LoadUtils.getBg(scene);
        const snowAnims = LoadUtils.getCurrentStyleAnim(scene);        

        scene.time.delayedCall(15000, () => {
            snowAnims.forEach(anim => {
                anim.setVisible(false);
                anim.destroy();
            });
            completeCallback && completeCallback();
        });
    }

    public static drawLoadingScreen2(scene: Scene, fromAlpha: number, toAlpha: number,
        completeCallback: () => unknown, delay: number, dur: number): any {
        const bg = LoadUtils.getBg(scene);
        const snowAnims = LoadUtils.getCurrentStyleAnim(scene);
        snowAnims.forEach(anim => {
            anim.setDepth(10000);
        });

        scene.tweens.add({
            targets: bg,
            alpha: { value: {from: fromAlpha, to: 0.5 * toAlpha}, duration: dur, delay },
            onStop() {
                snowAnims.forEach(anim => {
                    anim.setVisible(false);
                    anim.destroy();
                });
            },
            onComplete() {
                completeCallback();
            }
        });
    
        scene.tweens.add({
            targets: bg,
            x: '+=' + (dur / 100),
            alpha: toAlpha,
            duration: dur,
            delay
        });

        let res = new Phaser.GameObjects.Group(scene, [bg]).setDepth(10000);
        scene.add.group(res);
    
        return { group: res, graphics: bg };
    }

    public static getBg(scene: Scene): Graphics {
        const graphics = scene.add.graphics().fillStyle(0x000000, 1);
        return graphics.fillRect(-50, -50, scene.game.canvas.width + 50, scene.game.canvas.height + 50).setDepth(0);
    }

    public static getCurrentStyleAnim(scene: Scene, speedCorrection?: number): Array<ParticleEmitter> {
        let anims: Array<ParticleEmitter> = [];

        switch (Urls.CURRENT_LOADING_ANIM_STYLE) {
            case LoadingAnimStyle.NewYear:
                anims = LoadUtils.getSnowAnim(scene, speedCorrection);
                break;
            case LoadingAnimStyle.Ordinary:
                anims = LoadUtils.getSparksAnim(scene, speedCorrection);
                break;
        }

        return anims;
    }

    public static getSnowAnim(scene: Scene, speedCorrection: number = 1): Array<ParticleEmitter> {
        const ANIM = scene.add.particles(0, 0, Keys.IMG_SNOW, {
            x: { min: -50 * CU.S, max: 0, steps: 2 },
            y: { min: -400 * CU.S, max: 600 * CU.S, steps: 2 },
            scale: { min: 0.1 * CU.S, max: 0.3 * CU.S, steps: 0.002 },
            lifespan: 13000,
            angle: { min: 0, max: 180, steps: 3 },
            alpha: { min: 0.7, max: 1, steps: 0.04 },
            speedX: { min: 30 * CU.S * speedCorrection, max: 100 * CU.S * speedCorrection, steps: 8 },
            speedY: { min: 30 * CU.S * speedCorrection, max: 100 * CU.S * speedCorrection, steps: 8 },
            rotate: { start: 0, end: 360 * 3 },
            quantity: 4,
            frequency: 300
        }).setDepth(0);

        return [ANIM]; 
    }

    public static getSparksAnim(scene: Scene, speedCorrection: number = 1) {
        const SPARK_ANIM1 = scene.add.particles(0, 0, Keys.IMG_SPARK1, {
            x: { min: -50 * CU.S, max: 0, steps: 2 },
            y: { min: 300 * CU.S, max: scene.game.canvas.height, steps: 2 },
            scale: { min: 0.1 * CU.S, max: 0.3 * CU.S, steps: 0.002 },
            lifespan: 13000,
            alpha: { min: 0.7, max: 1, steps: 0.04 },
            speedX: { min: 100 * CU.S * speedCorrection, max: 150 * CU.S * speedCorrection, steps: 8 },
            speedY: { min: -150 * CU.S * speedCorrection, max: 0, steps: 8 },
            quantity: 1,
            frequency: 2000
        }).setDepth(1);

        const SPARK_ANIM2 = scene.add.particles(0, 0, Keys.IMG_SPARK2, {
            x: { min: -50 * CU.S, max: 0, steps: 2 },
            y: { min: 300 * CU.S, max: scene.game.canvas.height, steps: 2 },
            scale: { min: 0.2 * CU.S, max: 0.4 * CU.S, steps: 0.002 },
            lifespan: 13000,
            angle: { min: 0, max: 180, steps: 3 },
            alpha: { min: 0.7, max: 1, steps: 0.04 },
            speedX: { min: 200 * CU.S * speedCorrection, max: 250 * CU.S * speedCorrection, steps: 8 },
            speedY: { min: -200 * CU.S * speedCorrection, max: 20 * CU.S * speedCorrection, steps: 8 },
            rotate: { start: 0, end: 360 * 3 },
            quantity: 5,
            frequency: 1500
        }).setDepth(1);

        const SPARK_ANIM3 = scene.add.particles(0, 0, Keys.IMG_SPARK3, {
            x: { min: -50 * CU.S, max: 0, steps: 2 },
            y: { min: 300 * CU.S, max: scene.game.canvas.height, steps: 2 },
            scale: { min: 0.1 * CU.S, max: 0.3 * CU.S, steps: 0.002 },
            lifespan: 13000,
            angle: { min: 0, max: 180, steps: 3 },
            alpha: { min: 0.7, max: 1, steps: 0.04 },
            speedX: { min: 100 * CU.S * speedCorrection, max: 250 * CU.S * speedCorrection, steps: 8 },
            speedY: { min: -200 * CU.S * speedCorrection, max: 20 * CU.S * speedCorrection, steps: 8 },
            rotate: { start: 0, end: 360 * 3 },
            quantity: 3,
            frequency: 1500
        }).setDepth(1);

        return [SPARK_ANIM1, SPARK_ANIM2, SPARK_ANIM3];
    }

    public static drawClouds2(scene:Scene, canvas: HTMLCanvasElement, fromAlpha: number, toAlpha: number,
                       completeCallback: () => unknown, delay: number, dur: number){
        let loadBarBack = scene.add.graphics();
        loadBarBack.fillStyle(0x000000, 1);
        loadBarBack.fillRect(0, 0, canvas.width, canvas.height);
        loadBarBack.setAlpha(fromAlpha)
        let bg = SpriteUtils.addSprite(scene, canvas.width / 2 / CU.S,canvas.height / 2 / CU.S,  Keys.CLOUD2).setScale(5, 15).setAlpha(fromAlpha);

        let scale = 1.1 ;

        let clouds:Sprite[] = []
        for (let i=0; i<4;i++){
            for (let j=0; j<9;j++){
                let cloud = SpriteUtils.addSprite(scene, 0+250*i*scale + j*60*scale - 250,0+140*j*scale,  Keys.CLOUD2).setScale(scale * CU.S).setAlpha(fromAlpha);
                clouds.push(cloud);                
                if((i+j)%2 == 0){
                    cloud.scaleX *=-1;
                }
                if((i+j)%4 == 0){
                    cloud.scaleY *=-1;
                }
            }
        }

        scene.tweens.add({
            targets: [bg],
            alpha: 0.5*toAlpha,
            duration: dur,
            delay:delay,
            onStop() {
                clouds.forEach(s=> s.destroy())
            },

            onComplete(){
                completeCallback();
            }
        });

        scene.tweens.add({
            targets: [...clouds, loadBarBack],
            x: '+=' + (dur/100),
            alpha: toAlpha,
            duration: dur,
            delay:delay,
            onStop() {
                clouds.forEach(s=> s.destroy())
            },
        });
        let res = new Phaser.GameObjects.Group(scene,[bg, ...clouds]);
        scene.add.group(res);

        res.setDepth(10000);
        loadBarBack.setDepth(10000);

        return {group: res, graphics: loadBarBack};
    }
}

export class LoaderState {

    targetPercent: number = 10;
    percent = 0;
    percentFloor = 0;
    innerBarMaxWidth = 263;
    lastUpdate = 0;
    frameCounter = 0;
    loadBarOuter!: Sprite;
    loadingText!: Text;

    public update(time: number, delta: number){
        ++this.frameCounter;
        if (this.frameCounter < 3) {
            return;
        }
        this.frameCounter = 0;
        delta = delta * 3;
        if (this.percentFloor < this.targetPercent) {
            let speed = Math.max(0,  (this.targetPercent - this.percent) / 1000);
            this.percent = this.percent + speed * delta;
            if (this.targetPercent === 100) {
                this.percent = 100;
            }
            let newPercentFloor = Math.ceil(this.percent);
            if (newPercentFloor !== this.percentFloor) {
                this.percentFloor = Math.ceil(this.percent);
                this.loadBarOuter.displayWidth = this.innerBarMaxWidth * CU.S * this.percentFloor / 100;
                if (this.loadingText.active) {
                    this.loadingText.setText(Tr('zagruzka') + ' ' + String(this.percentFloor) + '%');
                }
            }
        } else if (Date.now() - this.lastUpdate > 500) {

            let overhead = this.percentFloor - this.targetPercent;
            let delta = 0;
            if (overhead == 0) {
                delta = 1;
            } else if (overhead == 1 || overhead == 2) {
                delta = 0.5;
            } else if (overhead == 3 || overhead == 4) {
                delta = 0.25;
            }

            this.percent = Math.min(this.percent + delta, 100);
            this.lastUpdate = Date.now();
            this.percentFloor = Math.ceil(this.percent);
            this.loadBarOuter.displayWidth = this.innerBarMaxWidth * CU.S * this.percentFloor / 100;
            if (this.loadingText.active) {
                this.loadingText.setText(Tr('zagruzka') + ' ' + String(this.percentFloor) + '%');
            }
        }
    }


}
